<template>
    <div class="end-sharing-content">
        <ShWidgetStepper
            :model="variant === 'coupon' || variant === 'offer' ? 'normal' : 'mini'"
            :step="variant === 'coupon' || variant === 'offer' ? 3 : 2"
            class="mt-6"
        />
        <main class="end-sharing-content__main">
            <div class="main-wrapper">
                <template v-if="revision === 'true'">
                    <picture class="main-wrapper__picture">
                        <img :src="$assets.primary.pending" alt="Ready" />
                    </picture>
                    <hgroup class="main-wrapper__text">
                        <h2>
                            {{ $lang.pages.compartirFinalizado.post_received }}
                            {{ type.wordSingular + (title ? ':' : '') }}
                            <span v-if="title">"{{ title }}"</span>
                            {{ $lang.pages.compartirFinalizado.in_revision }}
                            {{ $lang.pages.compartirFinalizado.when_published }}
                        </h2>
                        <h3>{{ $lang.pages.compartirFinalizado.be_patient }}</h3>
                    </hgroup>
                    <div class="main-wrapper__actions bordered">
                        <h4>Mientras que se publica tu {{ type.wordSingular }}, te recomendamos:</h4>
                        <div class="buttons">
                            <NuxtLink
                                no-prefetch
                                :to="`/${$lang.routes.offers}`"
                                class="buttons__offers buttons__links"
                            >
                                <div class="image">
                                    <img :src="$assets.white.offers" alt="ofertas" />
                                </div>
                                {{ $lang.pages.compartirFinalizado.see_offers }}
                            </NuxtLink>
                            <NuxtLink
                                no-prefetch
                                :to="`/${$lang.routes.coupons}`"
                                class="buttons__cupons buttons__links"
                            >
                                <div class="image">
                                    <img :src="$assets.white.couponsMenu" alt="cupones" />
                                </div>
                                {{ $lang.pages.compartirFinalizado.see_coupons }}
                            </NuxtLink>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <picture class="main-wrapper__picture">
                        <img :src="$assets.primary.success" alt="Ready" />
                    </picture>
                    <div class="main-wrapper__actions">
                        <h2>
                            ¡{{ $lang.pages.compartirFinalizado.your }} {{ type.wordSingular }}:
                            <span v-if="title">"{{ title }}"</span>
                            {{ $lang.pages.compartirFinalizado.published }}!
                        </h2>
                        <div class="buttons">
                            <NuxtLink
                                no-prefetch
                                :to="`${type.path}/${Route.query.slug}`"
                                class="buttons__links buttons__cupons"
                            >
                                <div class="image">
                                    <img
                                        v-if="variant !== 'chat'"
                                        :src="
                                            variant === 'coupon'
                                                ? $assets.white.coupons
                                                : $assets.white.offers
                                        "
                                        :alt="variant"
                                    />
                                </div>
                                {{ $lang.pages.compartirFinalizado.see_post }} {{ type.wordSingular }}
                            </NuxtLink>
                        </div>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
type Variants = 'coupon' | 'offer' | 'chat'

definePageMeta({ middleware: ['auth-required'] })

const Route = useRoute()

const { $assets, $lang } = useNuxtApp()

const variant = Route.query.variant as Variants
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()
const type = variants[variant]

const title = typeof Route.query.title === 'string' ? decodeURIComponent(Route.query.title) : null

const revision = Route.query.revision || false

const {
    public: { origin },
} = useRuntimeConfig()

const seoTitle = $lang.pages.compartirFinalizado.title_seo
const seoDescription = $lang.pages.compartirFinalizado.description_seo

useSeoMeta({
    title: seoTitle,
    ogUrl: origin + Route.path,
    ogTitle: seoTitle,
    description: seoDescription,
    ogDescription: seoDescription,
    ogImageAlt: seoTitle,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.end-sharing-content {
    @apply container mb-8 lg:max-w-screen-xl;
    &__main {
        @apply mx-auto mt-10 flex max-w-3xl justify-center rounded-xl bg-white p-5;
        .main-wrapper {
            @apply max-w-3xl space-y-4;
            &__picture {
                @apply mx-auto block h-52 w-52;
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__text {
                @apply flex flex-col text-center;
                h2 {
                    span {
                        @apply font-medium;
                    }
                }
                h3 {
                    @apply font-semibold;
                }
            }
            &__see-sharing-content {
                @apply flex justify-center font-medium text-gray-800 underline underline-offset-4 hover:text-gray-800;
            }
            &__actions {
                @apply flex flex-col items-center space-y-3 p-9 py-3;
                h2 {
                    @apply text-xl font-semibold;
                    span {
                        @apply text-site-primary;
                    }
                }
                h4 {
                    @apply text-center font-semibold;
                }
                .buttons {
                    @apply block gap-3 space-y-2 sm:flex sm:space-y-0;
                    &__links {
                        @apply flex items-center justify-center gap-1 rounded-lg p-2 text-sm text-white sm:text-base;
                        .image {
                            @apply self-center;
                            img {
                                @apply h-6;
                            }
                        }
                    }
                    &__offers {
                        @apply bg-site-secondary;
                    }
                    &__cupons {
                        @apply bg-site-primary;
                    }
                }
            }
            .bordered {
                @apply rounded-xl border shadow-lg;
            }
        }
    }
}
</style>
